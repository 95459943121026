<!-- SLIDER & CONSLIDER -->
<form [formGroup]="sliderFormGroup">
  @if (this.question.type === this.questionEnum.slider || this.question.type === this.questionEnum.conSlider) {
    <mat-form-field>
      <mat-label>{{ this.question.answers[0].label }}</mat-label>
      <input
        #sliderInput
        inputmode="numeric"
        formControlName="text"
        [attr.data-testcafe]="'slider-description-' + testcafeLabel"
        [pattern]="numRegex"
        (change)="textToSlider(); sendAnswerSlider()"
        matInput
      />
      @if (this.question.answers[0].tooltip) {
        <ng-template
          *ngTemplateOutlet="infoIcon; context: { description: this.question.answers[0].tooltip }"
        ></ng-template>
      }
      <mat-error>
        @if (sliderFormGroup.get('text').invalid) {
          <kf-input-error [message]="getNumInputError()" />
        }
      </mat-error>
    </mat-form-field>
    <div>{{ firstLabelDependent }}</div>
    <mat-slider #slider [min]="this.min" [max]="this.max" [step]="step" [showTickMarks]="showTickMarks">
      <input
        matSliderThumb
        formControlName="slider"
        (input)="sliderToText({ parent: slider, source: sliderThumb, value: sliderThumb.value }, false)"
        (change)="sendAnswerSlider()"
        #sliderThumb="matSliderThumb"
      />
    </mat-slider>
    <ul class="d-flex justify-content-between list-unstyled slider-label-container">
      @if (showTickMarks) {
        @for (label of this.labelArray; track label) {
          <li class="text-center slider-label">{{ label.toLocaleString() }}</li>
        }
      } @else {
        <li class="text-center slider-label">{{ this.min.toLocaleString() }}</li>
        <li class="text-center slider-label">{{ this.max.toLocaleString() }}</li>
      }
    </ul>
    @if (this.question.type === this.questionEnum.conSlider) {
      <!-- CONSLIDER -->
      <div class="mt-5">{{ secondLabelDependent }}</div>
      <mat-slider #conSlider [min]="this.min" [max]="this.max" [step]="step">
        <input
          matSliderThumb
          formControlName="conSlider"
          (input)="sliderToText({ parent: conSlider, source: conSliderThumb, value: conSliderThumb.value }, true)"
          (change)="sendAnswerSlider()"
          #conSliderThumb="matSliderThumb"
        />
      </mat-slider>
      <ul class="d-flex justify-content-between list-unstyled slider-label-container">
        <li class="text-center slider-label">{{ this.min.toLocaleString() }}</li>
        <li class="text-center slider-label">{{ this.max.toLocaleString() }}</li>
      </ul>
    }
  } @else if (this.question.type === this.questionEnum.expSlider) {
    <!-- DECLOGSLIDER -->
    <mat-form-field>
      <mat-label>{{ this.question.answers[0].label }}</mat-label>
      <input
        #expSliderInput
        inputmode="numeric"
        formControlName="expText"
        [pattern]="numRegex"
        (change)="expTextToSlider(); sendAnswerSlider()"
        matInput
      />
      @if (this.question.answers[0].tooltip) {
        <ng-template
          *ngTemplateOutlet="infoIcon; context: { description: this.question.answers[0].tooltip }"
        ></ng-template>
      }
      <mat-error>
        @if (sliderFormGroup.get('expText').invalid) {
          <kf-input-error [message]="getNumInputError()" />
        }
      </mat-error>
    </mat-form-field>
    <mat-slider
      #expSlider
      id="expSlider"
      [min]="this.sliderPropsMap.get(question.id).expSliderMin"
      [max]="this.sliderPropsMap.get(question.id).expSliderMax"
      [step]="step"
    >
      <input
        matSliderThumb
        formControlName="expSlider"
        (input)="expSliderToText({ parent: expSlider, source: expSliderThumb, value: expSliderThumb.value })"
        (change)="sendAnswerSlider()"
        #expSliderThumb="matSliderThumb"
      />
    </mat-slider>
    <div class="d-flex justify-content-between tick-mark-container">
      @for (mark of this.labelArray; track mark; let index = $index) {
        @if (min === 0 && index === 0) {
          <!--push following marks by 1 step-width for 0-->
          <div class="tick-mark" [style]="getMargin()"></div>
        } @else {
          <div class="tick-mark"></div>
        }
      }
    </div>
    <ul class="d-flex list-unstyled slider-label-container">
      @for (label of this.labelArray; track label; let index = $index) {
        @if (index === this.labelArray.length - 1) {
          <li id="label-{{ index }}" class="slider-label slider-label--exp-last" [style]="getLabelPositions(index)">
            {{ label.toLocaleString() }}
          </li>
        } @else {
          <li id="label-{{ index }}" class="slider-label slider-label--exp" [style]="getLabelPositions(index)">
            {{ label.toLocaleString() }}
          </li>
        }
      }
    </ul>
  }
  @if (this.hasTextField) {
    <mat-form-field>
      <mat-label>Notizen</mat-label>
      <textarea
        #sliderNote
        [attr.data-testcafe]="'slider-note-' + this.testcafeLabel"
        formControlName="note"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="4"
        (change)="sendAnswerSlider()"
        matInput
      ></textarea>
    </mat-form-field>
    <div>
      {{ this.sliderFormGroup.value.note }}
    </div>
  }
</form>

<ng-template #infoIcon let-description="description">
  <kf-icon
    [kfTooltip]="description"
    [attr.data-testcafe]="'slider-infoIcon-' + this.testcafeLabel"
    alt="Details"
    class="ml-2 mt-2 icon-is-interactive"
    kfTooltipPosition="after"
    kfTooltipShow="click"
    name="ic_infozeichen-mit-kreis_24"
    role="button"
    tabindex="0"
  ></kf-icon>
</ng-template>
