import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSliderDragEvent } from '@angular/material/slider';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { UpdateAnswerItem } from '@domain/app/answers.domain';
import { QuestionGroupResponse, QuestionItem } from '@domain/app/question.domain';
import { GivenAnswerEnum, QuestionEnum } from '@enums';
import { environment } from '@environment/environment';
import { NumberMinMaxData, ValueObject, sliderProperties } from '@screens/screen-main/screen-main.component';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';

@Component({
  selector: 'slider',
  standalone: true,
  imports: [MaterialModule, KfThemeModule, ReactiveFormsModule, MatInputModule, CommonModule],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss',
})
export class SliderComponent {
  @Input({ required: true }) question: QuestionItem;
  @Input({ required: true }) sliderPropsMap: Map<string, sliderProperties>;
  @Input({ required: true }) Data: Map<string, ValueObject>;
  @Input({ required: true }) MinMax: Map<string, NumberMinMaxData>;

  @Output() sliderSelected = new EventEmitter<any>();

  private currentValue = 0;

  public step: number;
  public showTickMarks: boolean;
  public labelArray = [];
  public stepArray = [];
  public min = 0;
  public max = 10;
  public hasTextField: boolean;
  public numRegex: RegExp;

  public questionGroupData: QuestionGroupResponse;

  public questionEnum = QuestionEnum;

  sliderFormGroup = new FormGroup({
    text: new FormControl<any>(0),
    slider: new FormControl<number>(0),
    conSlider: new FormControl<number>(0),
    expText: new FormControl<any>(0),
    expSlider: new FormControl<number>(0),
    note: new FormControl<string>(''),
  });

  constructor(
    private clientService: ClientService,
    private queryService: QueryService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    this.currentValue = this.Data.get(this.question.id).value;
    this.min = this.MinMax.get(this.question.id).min;
    this.max = this.MinMax.get(this.question.id).max;
    this.step = this.getStepValue();

    this.sliderFormGroup.patchValue({
      text: this.Data.get(this.question.id).value,
      expText: this.Data.get(this.question.id).value,
      note: this.Data.get(this.question.id).note,
    });

    if (this.question.type === this.questionEnum.expSlider) {
      this.expTextToSlider();
      this.labelArray = this.sliderPropsMap.get(this.question.id).sliderLabels;
      this.stepArray = this.sliderPropsMap.get(this.question.id).sliderSteps;
    } else {
      this.textToSlider();

      if (this.max / this.step <= 10) {
        this.labelArray.push(this.min);
        for (let i = this.step; i <= this.max; i = i + this.step) {
          this.labelArray.push(i);
        }
        this.showTickMarks = true;
      }
    }

    //Compute RegEx for valid values in number input
    //first digit separate because this.min can be 0 or 1
    this.numRegex = new RegExp(
      '(^[' + this.min + '-9][0-9]{0,' + (Math.log10(this.max) - 1) + '}$|^' + this.max + '$)'
    );
  }

  /***** DISPLAY DATA *****/

  textToSlider() {
    this.currentValue = this.sliderFormGroup.value.text;
    this.sliderFormGroup.patchValue({
      slider: this.currentValue,
      conSlider: this.max - this.currentValue,
    });
  }

  sliderToText(event: MatSliderDragEvent, isConSlider: boolean) {
    if (!isConSlider) {
      this.currentValue = event.value;
      this.sliderFormGroup.patchValue({
        text: this.currentValue,
        conSlider: this.max - this.currentValue,
      });
    } else {
      this.currentValue = this.min === 0 ? this.max - event.value : this.max - (event.value - 1);
      this.sliderFormGroup.patchValue({
        text: this.currentValue,
        slider: this.currentValue,
      });
    }
  }

  expTextToSlider() {
    this.currentValue = this.sliderFormGroup.value.expText;
    let sliderValue = this.currentValue;
    if (sliderValue <= 0) {
      this.sliderFormGroup.patchValue({ expSlider: 0 });
      return;
    }

    let powerOfTen = Math.pow(10, Math.floor(Math.log10(sliderValue)));
    sliderValue = Math.round(sliderValue / powerOfTen) * powerOfTen; //round to first digit

    const sliderStep = this.sliderPropsMap.get(this.question.id).sliderSteps.indexOf(sliderValue);
    this.sliderFormGroup.patchValue({ expSlider: sliderStep });
  }

  expSliderToText(event: MatSliderDragEvent) {
    this.currentValue = this.sliderPropsMap.get(this.question.id).sliderSteps[event.value];
    this.sliderFormGroup.patchValue({ expText: this.currentValue });
  }

  /***** SEND DATA *****/

  public sendAnswerSlider() {
    const answerObj = this.question.answers;
    const sendObj = [] as UpdateAnswerItem[];
    const questionId = this.question.id;
    let answerValue = this.currentValue;

    for (const answer of answerObj) {
      if (answer.minValue <= answerValue && answer.maxValue >= answerValue) {
        sendObj.push({
          id: answer.id,
          value: answerValue.toString(),
          note: this.sliderFormGroup.value.note || '',
          answeredBy: GivenAnswerEnum.none,
        });
        break;
      }
    }

    if (sendObj.length === 0) {
      return;
    }

    sendObj[0].answeredBy = this.answerType;
    this.queryService.putAnswerFromGroup(this.clientService.consultationId, questionId, sendObj).subscribe();
    this.sliderSelected.emit();
  }

  /***** ADJUST HTML PRESENTATION *****/

  public getMargin(): string {
    let expSliderWidth = document.getElementById('expSlider').offsetWidth;
    let stepWidth = expSliderWidth / this.stepArray.length;
    return 'margin-right: ' + stepWidth + 'px';
  }

  public getLabelPositions(index: number): string {
    const expSliderWidth = document.getElementById('expSlider').offsetWidth;
    let labelDistance = expSliderWidth / Math.log10(this.max);

    if (this.min !== 0 || index === 0) {
      //power of ten on pos 0 -> equal distances to following powers
      return 'left: ' + (labelDistance * index).toString() + 'px';
    } else {
      const stepWidth = expSliderWidth / this.stepArray.length;
      const distOne = labelDistance + stepWidth;
      const numLabel = this.labelArray.length;

      //first range 1 step bigger because of 0
      if (index === 1) {
        return 'left: ' + distOne.toString() + 'px';
      }
      labelDistance = ((expSliderWidth - distOne) / (numLabel - 2)) * (index - 1);
      return 'left: ' + (distOne + labelDistance).toString() + 'px';
    }
  }

  public getStepValue(): number {
    if (this.question.stepValue === 0) {
      return 1;
    } else {
      return this.question.stepValue;
    }
  }

  public getNumInputError(): string {
    return (
      'Bitte geben Sie einen Wert zwischen ' + this.min.toLocaleString() + ' und ' + this.max.toLocaleString() + ' an.'
    );
  }

  /***** GETTER *****/

  get answerType(): GivenAnswerEnum {
    if (environment.consultationType === 'prelim') {
      return GivenAnswerEnum.customer;
    } else {
      this.contextService.currentMode === 'main' ? GivenAnswerEnum.consultation : GivenAnswerEnum.consultant;
    }
    return GivenAnswerEnum.customer;
  }

  get testcafeLabel(): string {
    return this.question.name.split(' #/#')[0];
  }

  get firstLabelDependent(): string {
    return this.question.name.includes('#/#') ? this.question.name.split('#/#')[1] : '';
  }

  get secondLabelDependent(): string {
    return this.question.name.includes('#/#') ? this.question.name.split('#/#')[2] : '';
  }

  get labelMain(): string {
    return this.question.name.includes('#/#') ? this.question.name.split('#/#')[0] : this.question.name;
  }
}
