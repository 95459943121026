import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnswerOptionItem, GivenAnswerItem, OtherAnswers, UpdateAnswerItem } from '@domain/app/answers.domain';
import { MediaItem } from '@domain/app/media.domain';
import { MessagesRequestItem } from '@domain/app/messages.domain';
import { QuestionGroupResponse, QuestionItem, SyncedAnswers } from '@domain/app/question.domain';
import { QuestionGroupRecommendationResponse } from '@domain/app/recommendation.domain';
import { TopicOverviewItem } from '@domain/app/topic.domain';
import {
  GivenAnswerEnum,
  MediaTypeEnum,
  QuestionEnum,
  QuestionTemplateEnum,
  RoutingPathMain,
  TopicStatusEnum,
} from '@enums';
import { environment } from '@environment/environment';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { MediaService } from '@services/media-service/media.service';
import { QueryService } from '@services/query-service/query.service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

interface VrImageData {
  url: string;
  altText?: string;
  selected?: boolean;
}

interface SegmentedLabelData {
  id: string;
  label: string;
  checked: boolean;
}

interface ImageAnswerData {
  id: string;
  answertext: string;
  imageUrl: string;
  checked: boolean;
}

export interface NumberMinMaxData {
  min: number;
  max: number;
}

export interface sliderProperties {
  expSliderMin: number;
  expSliderMax: number;
  sliderSteps: number[];
  sliderLabels: number[];
  hasTextField: boolean;
}

export interface ValueObject {
  value: number;
  defaultSliderValue: boolean;
  note?: string;
  answerId?: string;
}

interface TextAnswerData {
  questionId: string;
  value: boolean | any;
  type: 'text' | 'text-input' | 'single' | 'multi';
  index?: number;
  answerObj?: AnswerOptionItem[];
}

interface PreviousAnswerRequest {
  consultationId: string;
  questionId: string;
  sendObj: any;
}
@Component({
  selector: 'screen-main',
  templateUrl: './screen-main.component.html',
  styleUrls: ['./screen-main.component.scss'],
})
export class ScreenMainComponent implements OnInit, OnDestroy {
  @ViewChild('questionGroupContainer', { static: false }) questionGroupContainer: ElementRef;
  menuData: TopicOverviewItem[];
  otherMenuData: TopicOverviewItem[];

  readonly UUIDNull = '00000000-0000-0000-0000-000000000000';

  private tempTextAnswer: TextAnswerData | null = null;
  private previousAnswerRequest: PreviousAnswerRequest | null = null;

  private destroySubs = new Subject<void>();
  private prevAnswerObj: UpdateAnswerItem[];
  private questionGroupId = this.UUIDNull;

  private parent: any;
  private focusElement: any;

  private mediaMap: Map<string, string> = new Map<string, string>();

  public routeMain = RoutingPathMain;
  public questionGroupData: QuestionGroupResponse;
  public recommendationGroupData: QuestionGroupRecommendationResponse;
  public imageData: VrImageData[] = [];
  public questionCollectionError = false;

  public questionEnum = QuestionEnum;
  public questionTemplateEnum = QuestionTemplateEnum;
  public mediaTypeEnum = MediaTypeEnum;
  public availableCollections;
  public currentIndex = 0;
  public questionGroupLoading = true;
  public messageSending: boolean = false;

  public helperArray = Array;

  public readonly color = color;
  public readonly buttonIcon = libIcons;

  public requestPending = false;

  // Answer Maps
  public singleAnswerData: Map<string, string> = new Map<string, string>();
  public numericalAnswerData: Map<string, ValueObject> = new Map<string, ValueObject>();
  public numericalAnswerMinMax: Map<string, NumberMinMaxData> = new Map<string, NumberMinMaxData>();
  public segementedAnswerData: Map<string, SegmentedLabelData[]> = new Map<string, SegmentedLabelData[]>();
  public imageAnswerData: Map<string, ImageAnswerData[]> = new Map<string, ImageAnswerData[]>();
  public showTextMap: Map<string, any> = new Map<string, any>();
  public otherAnswersMap: Map<string, OtherAnswers> = new Map<string, OtherAnswers>();
  public sliderPropsMap: Map<string, sliderProperties> = new Map<string, sliderProperties>();
  public showSyncQuestionSet: Set<string> = new Set<string>();
  public showTextField = false;
  consultationMessageData: any;
  consultationMessages: any;

  constructor(
    private router: Router,
    private actionService: ActionService,
    private clientService: ClientService,
    private queryService: QueryService,
    private mediaService: MediaService,
    private dialogService: DialogService,
    private contextService: ContextService,
    private configService: ConfigService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.destroySubs)).subscribe(urlParam => {
      if (urlParam.consultationId) {
        this.queryService
          .getPreliminaries()
          .pipe(takeUntil(this.destroySubs))
          .subscribe(prelims => {
            const selectedPrelim = prelims.find(y => y.id === urlParam.consultationId);
            this.clientService.consultationId = selectedPrelim.id;
            this.clientService.customerId = selectedPrelim.customerId;
            this.clientService.instanceId = selectedPrelim.instanceId;
          });
      }
    });
  }

  ngOnInit(): void {
    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'prelim-main') {
        if (action.action === 'next') {
          // get the data for the next question-collection
          this.sendTempTextRequest();
          if (this.currentIndex < this.availableCollections.length) {
            this.currentIndex++;
          }
          this.queryQuestionCollectionData(
            this.clientService.consultationId,
            this.availableCollections[this.currentIndex]
          );
        }
        if (action.action === 'prev') {
          this.sendTempTextRequest();
          if (this.currentIndex > 0) {
            this.currentIndex--;
          }

          // get the data for the prev question-collection
          this.queryQuestionCollectionData(
            this.clientService.consultationId,
            this.availableCollections[this.currentIndex]
          );
        }
        if (action.action === 'jump') {
          this.sendTempTextRequest();

          // get the data for the next question-collection
          this.queryQuestionCollectionData(this.clientService.consultationId, action.options.questionGroupId);
        }
        if (action.action === 'finish-subtopic') {
          this.sendTempTextRequest();

          this.router.navigate([this.routeMain.Done], {
            state: {
              subtopicId: this.subtopicId,
              nextQuestionGroupId: this.questionGroupData.nextCollectionId,
              prevQuestionGroupId: this.questionGroupData.collectionId,
              allDone: this.questionGroupData.lastCollectionTotal,
            },
          });
        }
      }
    });

    if (this.clientService.consultationId) {
      this.handleTopicQuery();
    }
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.complete();
  }

  get countTopics() {
    return this.menuData?.length;
  }

  public openDialog() {
    this.dialogService.openDialogConfirm({
      questionText: 'Ihr Berater hat bereits einige Fragen als Empfehlungen für Sie beantwortet.',
      confirmText: 'Okay, verstanden',
    });
  }

  public doAction(target: string = '', action: string = '', options?: any) {
    this.questionGroupLoading = true;
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public setFocusElement(event: any, parent: any = null) {
    this.parent = parent;
    this.focusElement = event;
  }

  public reformatHTML(code) {
    return `<article>${code}</article>`;
  }

  get subtopicId() {
    return this.questionGroupData.subtopicId || 0;
  }
  get questionCollectionId() {
    return this.questionGroupData.collectionId || 0;
  }
  get isFirstQuestionInSubtopic() {
    return this.questionGroupData.currentCollection === 0;
  }
  get isLastQuestionInSubtopic() {
    return this.questionGroupData.lastCollectionSubtopic;
  }
  get totalQuestionCollectionsInSubtopic() {
    return this.questionGroupData.totalCollections;
  }

  get recDataSelected() {
    return this.recommendationGroupData.selected || [];
  }
  get recDataRecommended() {
    return this.recommendationGroupData.recommended || [];
  }
  get recDataOther() {
    return this.recommendationGroupData.other || [];
  }

  get completedTopics() {
    return this.menuData.filter(x => x.status === TopicStatusEnum.done).length;
  }

  public nextTopic() {
    this.queryService
      .putSubtopicComplete(this.clientService.consultationId, this.questionGroupData.subtopicId)
      .subscribe();
    this.doAction('prelim-main', 'next');
  }

  public onFinishPrelim() {
    // TODO: Update to status, maybe.
    this.queryService
      .putSubtopicComplete(this.clientService.consultationId, this.questionGroupData.subtopicId)
      .subscribe();
    this.sendMessage();
    this.backtoStartScreen();
  }

  public sendMessage() {
    const message = 'Ich habe die Vorbefragung abgeschlossen.';
    const sendData: MessagesRequestItem = { sender: GivenAnswerEnum.customer, text: message };
    this.queryService
      .postMessage(this.clientService.consultationId, sendData)
      .pipe(
        finalize(() => {
          this.messageSending = false;
        })
      )
      .subscribe();
  }

  public backtoStartScreen() {
    this.router.navigate([
      `${RoutingPathMain.Start}/${this.clientService.customerId}/${this.clientService.consultationId}`,
    ]);
  }

  // -------  HELPER FUNCTIONS -------- //

  public focusAnswer(): void {
    if (!this.focusElement) {
      return;
    }

    const targetRef = document.getElementById(this.focusElement.id || this.parent);
    let htmlCollection = [];

    if (!this.focusElement?.id) {
      const focusRef = this.focusElement.ref;
      const regularClassName = targetRef?.getElementsByClassName(focusRef?.className);
      const htmlElement =
        regularClassName?.length !== 0
          ? regularClassName
          : targetRef?.getElementsByClassName(focusRef?._elementRef?.nativeElement.className);
      htmlCollection = htmlElement && htmlElement.length > 0 ? [htmlElement[0]] : [];
    } else {
      htmlCollection = [targetRef];
    }

    if (htmlCollection?.length > 0) {
      let input = null;
      const focusTarget = htmlCollection[0] as HTMLInputElement;
      if (
        focusTarget?.className.includes('slider') ||
        focusTarget?.className.includes('mat-checkbox') ||
        focusTarget?.localName === 'input'
      ) {
        input = focusTarget;
      }
      if (focusTarget?.className.includes('image-button-container')) {
        input = focusTarget.getElementsByClassName('mat-checkbox')[0]?.querySelector('input') || focusTarget;
      } else {
        input = !focusTarget?.querySelector('input') ? focusTarget : focusTarget.querySelector('input');
      }
      input?.focus();
    }
  }

  public testcafeLabel(id: string, answer: string) {
    return `consultantAnswer-${answer.replace(/ /g, '')}-${id}`;
  }

  public historyGo(steps: number = -1) {
    window.history.go(steps);
  }

  public hasImage(question: QuestionItem): boolean {
    if (question?.medias.length === 0) {
      return false;
    }

    return question.medias && question.medias[0].type === MediaTypeEnum.image;
  }

  public handleQuery() {
    this.handleTopicQuery(false);
  }

  private handleTopicQuery(scrollToTop = true) {
    this.queryService
      .getTopicDataByConsultationId(this.clientService.consultationId, this.clientService.instanceId)
      .subscribe(
        topics => {
          this.availableCollections = topics.topics
            .flatMap(x => x.subtopics)
            .flatMap(y => y.questionGroups)
            .filter(u => u.showInPreliminary)
            .map(i => i.id);
          this.questionGroupId = this.availableCollections[this.currentIndex];

          this.menuData = topics.topics;
          if (topics.topics.length > 0) {
            this.queryQuestionCollectionData(this.clientService.consultationId, this.questionGroupId, scrollToTop);
          } else {
            this.questionCollectionError = true;
            this.requestPending = false;
          }
        },
        error => (this.requestPending = false)
      );
  }

  private queryQuestionCollectionData(consultationId: string, qcId: string, scrollToTop = true) {
    this.queryService.getQuestionCollectionById(consultationId, qcId).subscribe(async data => {
      this.questionGroupData = {
        ...data,
        questions: [...new Set(data.questions.map(a => a.id).map(id => data.questions.find(a => a.id === id)))],
      };
      this.showSyncQuestionSet.clear();

      // sets questionGroupData
      this.contextService.setCurrentQuestionGroup(this.questionGroupData);

      await this.handleImageData(this.questionGroupData);

      this.handleNextButtonState();
      this.handlePrevButtonState();
      this.handleOtherAnswers();

      // Functions for extracting the relevant data for the various question types.
      this.handleTextFieldData();
      this.handleSingleData();
      this.handleSliderData();
      this.handleSegmentRadioData();
      this.handleImageSelectData();

      this.handleDocumentData(data.medias.filter(x => x.type !== MediaTypeEnum.image && x.type !== MediaTypeEnum.logo));

      const elem = this.questionGroupContainer?.nativeElement;
      if (elem && scrollToTop) {
        elem.scrollTop = 0;
      }

      this.focusAnswer();

      this.questionGroupLoading = false;
      this.requestPending = false;
    });
  }

  private handlePrevButtonState() {
    if (this.isFirstQuestionInSubtopic) {
      this.doAction('footer', 'disable-prev');
    } else {
      this.doAction('footer', 'enable-prev');
    }
  }

  private handleNextButtonState() {
    if (this.isLastQuestionInSubtopic) {
      this.doAction('footer', 'last-question');
    } else {
      // something something disable when no answer selected
      this.doAction('footer', 'enable-next');
    }
  }

  private async handleImageData(data: QuestionGroupResponse) {
    this.imageData = [];

    // question group image
    const questionGroupImages = data.medias.filter(x => x.type === MediaTypeEnum.image);
    await Promise.all(
      questionGroupImages.map(async x => {
        const image = (await this.mediaService.getMediaContent(x.url)) || '';
        this.mediaMap.set(x.id, image);
      })
    );

    // question image
    const questionImages = data.questions.flatMap(x => x.medias.filter(y => y.type === MediaTypeEnum.image));
    await Promise.all(
      questionImages.map(async x => {
        const image = (await this.mediaService.getMediaContent(x.url)) || '';
        this.mediaMap.set(x.id, image);
      })
    );

    // answer images
    const answerImages = data.questions
      .flatMap(x => x.answers.flatMap(x => x.media))
      .filter(x => !!x && x.type === MediaTypeEnum.image);
    await Promise.all(
      answerImages.map(async x => {
        const image = (await this.mediaService.getMediaContent(x.url)) || '';
        this.mediaMap.set(x.id, image);
      })
    );
  }

  private handleDocumentData(documentData: MediaItem[]) {
    this.doAction('footer', 'document-info', { documents: documentData });
  }

  private syncedAnswerTextFactory(a: SyncedAnswers): string[] {
    return a.values.map(x => {
      const note = x.note ? `: [${x.note}]` : '';
      return `${x.value}${note}`;
    });
  }

  private handleSingleData() {
    this.singleAnswerData.clear();

    const myData = this.questionGroupData.questions.filter(
      x => x.type === this.questionEnum.single || x.type === this.questionEnum.toggle
    );

    for (const single of myData) {
      const questionWithAnswers = single.answers.filter(x =>
        x.givenAnswers.find(k => k.answeredBy === this.answerType)
      );
      if (questionWithAnswers.length > 0) {
        this.singleAnswerData.set(single.id, questionWithAnswers[0].id);
      }
    }
  }

  private handleSliderData() {
    this.numericalAnswerMinMax.clear();
    this.sliderPropsMap.clear();
    const myData = this.questionGroupData.questions.filter(x => {
      return (
        x.type === this.questionEnum.slider ||
        x.type === this.questionEnum.conSlider ||
        x.type === this.questionEnum.expSlider ||
        x.type === this.questionEnum.number
      );
    });

    try {
      for (const slider of myData) {
        const minArray = slider.answers.flatMap(x => x.minValue);
        const maxArray = slider.answers.flatMap(x => x.maxValue);
        const min = Math.min(...minArray); //overall smallest value for slider
        const max = Math.max(...maxArray); //overall biggest value for slider
        const minPower = min !== 0 ? Math.log10(min) : 0;
        const maxPower = Math.log10(max);
        let labels = [min];
        let steps = [min];

        if (slider.type === QuestionEnum.expSlider) {
          //get number of values including zeros for powers of ten labels
          const valueCount = (maxPower - minPower) * 10;

          //fill label and step arrays
          for (let i = min + 1; i <= valueCount; i++) {
            if (i % 10 !== 0) {
              steps.push((i % 10) * Math.pow(10, Math.trunc((i + minPower) / 10)));
            } else {
              labels.push(Math.pow(10, (i + minPower) / 10));
              if (i === valueCount) {
                steps.push(Math.pow(10, (i + minPower) / 10));
              }
            }
          }
        }
        this.numericalAnswerMinMax.set(slider.id, { min: min, max: max });

        const givenAnswers = slider.answers.flatMap(x => x.givenAnswers);
        const answerId = slider.answers.find(x => x.givenAnswers.find(y => y.answeredBy === this.answerType))?.id;
        const hasGivenAnswer = givenAnswers.findIndex(r => r.answeredBy === this.answerType) >= 0;
        const valueObject = hasGivenAnswer
          ? {
              value: Number(givenAnswers.find(r => r.answeredBy === this.answerType).value || 0),
              defaultSliderValue: false,
              note: givenAnswers.find(r => r.answeredBy === this.answerType).note,
              answerId: answerId,
            }
          : { value: Number(slider.defaultValue || 0), defaultSliderValue: true, note: '' };
        this.numericalAnswerData.set(slider.id, valueObject);

        const hasTextField = slider.answers.find(x => x.hasTextField === true) ? true : false;
        this.sliderPropsMap.set(slider.id, {
          expSliderMax: steps.length - 1,
          expSliderMin: 0,
          sliderSteps: steps,
          sliderLabels: labels,
          hasTextField: hasTextField,
        });
      }
    } catch (err) {}
  }

  private handleSegmentRadioData() {
    this.segementedAnswerData.clear();
    const myData = this.questionGroupData.questions.filter(x => x.type === this.questionEnum.segments);

    try {
      for (const segementedRadioData of myData) {
        const labelData = segementedRadioData.answers.map(y => {
          return {
            id: y.id,
            label: y.label,
            checked: y.givenAnswers.findIndex(r => r.answeredBy === this.answerType) >= 0,
          };
        });
        this.segementedAnswerData.set(segementedRadioData.id, labelData);
      }
    } catch (err) {}
  }

  private handleImageSelectData() {
    this.imageAnswerData.clear();
    const myData = this.questionGroupData.questions.filter(x => {
      return x.type === this.questionEnum.singleImage || x.type === this.questionEnum.multiImage;
    });

    try {
      for (const imageQuestionData of myData) {
        const answerData = imageQuestionData.answers.map(x => {
          return {
            id: x.id,
            answertext: x.label,
            imageUrl: x?.media?.url || '',
            checked: this.showPreselectedState(x.givenAnswers),
            tooltip: x.tooltip,
          };
        });
        this.imageAnswerData.set(imageQuestionData.id, answerData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  private handleTextFieldData() {
    const type = this.answerType;

    for (const question of this.questionGroupData.questions) {
      if (
        question.type === QuestionEnum.single ||
        question.type === QuestionEnum.number ||
        question.type === QuestionEnum.segments ||
        question.type === QuestionEnum.text
      ) {
        const filteredAnswers = question.answers.filter(y => y.hasTextField === true && y.givenAnswers.length > 0);
        if (
          filteredAnswers.length > 0 &&
          filteredAnswers[0].givenAnswers.filter(x => x.answeredBy === type).length > 0
        ) {
          this.showTextMap.set(question.id, [filteredAnswers[0]]);
          this.prevAnswerObj = this.createAnswerSendObject(filteredAnswers, type);

          if (question.type === QuestionEnum.single || question.type === QuestionEnum.segments) {
            delete this.prevAnswerObj[0].value;
          }
        }
      } else if (question.type === QuestionEnum.multi) {
        const filteredAnswers = question.answers.filter(y => y.hasTextField === true && y.givenAnswers.length > 0);
        if (
          filteredAnswers.length > 0 &&
          filteredAnswers.flatMap(x => x.givenAnswers).filter(x => x.answeredBy === type).length > 0
        ) {
          this.showTextMap.set(question.id, filteredAnswers);
        }
      } else if (
        question.type === QuestionEnum.slider ||
        question.type === QuestionEnum.expSlider ||
        question.type === QuestionEnum.conSlider
      ) {
        const filteredAnswers = question.answers.filter(y => y.hasTextField === true);
        if (filteredAnswers.length > 0) {
          this.showTextMap.set(question.id, filteredAnswers);
          this.prevAnswerObj = this.createAnswerSendObject(filteredAnswers, GivenAnswerEnum.consultation);
        }
      }
    }
  }

  private handleOtherAnswers() {
    this.otherAnswersMap.clear();

    const questions = this.questionGroupData.questions.slice();
    const initial = questions.map(x => {
      return { id: x.id, type: x.type, ans: x.answers?.filter(y => y.givenAnswers?.length > 0) || [] };
    });

    const initSync = questions
      .map(x => {
        return { id: x.id, type: x.type, ans: x.syncedAnswers };
      })
      .filter(y => y.ans?.length > 0);

    for (const sa of initSync) {
      const answers: OtherAnswers = {
        [GivenAnswerEnum.none]: [],
        [GivenAnswerEnum.customer]: [],
        [GivenAnswerEnum.consultant]: [],
        [GivenAnswerEnum.consultation]: [],
        [GivenAnswerEnum.history]: [],
      };

      for (const a of sa.ans) {
        if (a.answeredBy === GivenAnswerEnum.customer) {
          for (const v of a.values) {
            answers[GivenAnswerEnum.customer].push(`${v.value}${v.note ? ': [' + v.note + ']' : ''}`);
          }
        }
        if (a.answeredBy === GivenAnswerEnum.consultant) {
          for (const v of a.values) {
            answers[GivenAnswerEnum.consultant].push(`${v.value}${v.note ? ': [' + v.note + ']' : ''}`);
          }
        }
        if (a.answeredBy === GivenAnswerEnum.consultation) {
          for (const v of a.values) {
            answers[GivenAnswerEnum.consultation].push(`${v.value}${v.note ? ': [' + v.note + ']' : ''}`);
          }
        }
      }

      this.otherAnswersMap.set(sa.id, answers);
    }

    for (const q of initial) {
      const answers: OtherAnswers = {
        [GivenAnswerEnum.none]: [],
        [GivenAnswerEnum.customer]: [],
        [GivenAnswerEnum.consultant]: [],
        [GivenAnswerEnum.consultation]: [],
        [GivenAnswerEnum.history]: [],
      };

      for (const a of q.ans) {
        if (a.givenAnswers.some(x => x.answeredBy === GivenAnswerEnum.customer)) {
          const value = this.otherAnswerTextFactory(q.type, GivenAnswerEnum.customer, a);
          answers[GivenAnswerEnum.customer].push(value);
        }
        if (a.givenAnswers.some(x => x.answeredBy === GivenAnswerEnum.consultant)) {
          const value = this.otherAnswerTextFactory(q.type, GivenAnswerEnum.consultant, a);
          answers[GivenAnswerEnum.consultant].push(value);
        }
        if (a.givenAnswers.some(x => x.answeredBy === GivenAnswerEnum.consultation)) {
          const value = this.otherAnswerTextFactory(q.type, GivenAnswerEnum.consultation, a);
          answers[GivenAnswerEnum.consultation].push(value);
        }
      }

      if (q.ans.length > 0) {
        if (this.otherAnswersMap.has(q.id)) {
          const syncedAnswers = this.otherAnswersMap.get(q.id);
          for (const type in answers) {
            answers[type] = answers[type].concat(syncedAnswers[type]);
          }
        }
        this.otherAnswersMap.set(q.id, answers);
      }
    }
  }

  private otherAnswerTextFactory(type: QuestionEnum, answeredBy: GivenAnswerEnum, question: AnswerOptionItem): string {
    if (
      type === QuestionEnum.slider ||
      type === QuestionEnum.expSlider ||
      type === QuestionEnum.conSlider ||
      type === QuestionEnum.number ||
      type === QuestionEnum.text
    ) {
      // filter must have a value, otherwise wou wouldn't be here
      const value = question.givenAnswers.filter(x => x.answeredBy === answeredBy)[0].value;
      return question.label + ': ' + value;
    }

    if (question.hasTextField) {
      const value = question.givenAnswers.filter(x => x.answeredBy === answeredBy)[0].note;
      if (value) {
        return `${question.label}: ${value}`;
      }
    }

    return question.label;
  }

  private sendAnswer(consultationId: string, questionId: string, sendObject: UpdateAnswerItem[]) {
    if (this.tempTextAnswer) {
      this.setPreviousTextRequest(consultationId, questionId, sendObject);
      this.sendTempTextRequest();
    } else {
      this.queryService.putAnswerFromGroup(consultationId, questionId, sendObject).subscribe(data => {
        if (this.previousAnswerRequest) {
          this.setPreviousTextRequest(consultationId, questionId, sendObject);
          this.previousAnswerRequest = null;
          this.sendAnswer(
            this.previousAnswerRequest.consultationId,
            this.previousAnswerRequest.questionId,
            this.previousAnswerRequest.sendObj
          );
        }
        this.handleTopicQuery(false);
      });
    }
  }

  private setPreviousTextRequest(consultationId, questionId, sendObj) {
    this.previousAnswerRequest = { consultationId, questionId, sendObj };
  }

  private createAnswerSendObject(
    answerObj: AnswerOptionItem[],
    filter: GivenAnswerEnum | null = null
  ): UpdateAnswerItem[] {
    const sendObj = [];
    for (const answer of answerObj) {
      const thisAnswerId = answer.id;
      if (answer.givenAnswers.length > 0) {
        for (const givenAnswer of answer.givenAnswers) {
          const ansObj = {
            id: thisAnswerId,
            note: givenAnswer.note,
            value: givenAnswer.value,
            answeredBy: givenAnswer.answeredBy,
          };
          sendObj.push(ansObj);
        }
      } else {
        const ansObj = {
          id: thisAnswerId,
          note: null,
          value: null,
          answeredBy: GivenAnswerEnum.none,
        };
        sendObj.push(ansObj);
      }
    }

    if (filter !== null) {
      const filtered = sendObj.filter(x => x.answeredBy === filter);
      if (filtered.length === 0) {
        return [
          {
            id: this.UUIDNull,
            note: null,
            value: null,
            answeredBy: filter,
          },
        ];
      } else {
        return filtered;
      }
    }

    return sendObj;
  }

  public getOtherAnswerLabel(data: OtherAnswers): number {
    let returnValue = 0;

    if (data) {
      if (data.CUSTOMER.length > 0) {
        returnValue += 1;
      }
      if (this.contextService.currentMode === 'main' && data.CONSULTANT.length > 0) {
        returnValue += 1;
      }
    }

    return returnValue;
  }

  ////// ---------------------------------- //////
  ////// ---------------------------------- //////
  ////// ----- SEND ANSWER FUNCTIONS ------ //////
  ////// ---------------------------------- //////
  ////// ---------------------------------- //////
  public answerSelectMulti(answerObj: AnswerOptionItem[], questionId, index: number, value: boolean | any) {
    const selected = typeof value === 'boolean' ? value : value.selected;
    const notice = typeof value === 'boolean' ? null : value.note;

    let sendObj = [] as UpdateAnswerItem[];

    const givenAnswerObj = {} as GivenAnswerItem;
    givenAnswerObj.answeredBy = this.answerType;
    givenAnswerObj.note = notice || null;

    // Check if already answered in consultation / by consultant
    const givenAnswersIndex = answerObj[index].givenAnswers.findIndex(x => x.answeredBy === this.answerType);

    // Add CONSULTATION to givenAnswers Array
    if (selected && givenAnswersIndex === -1) {
      answerObj[index].givenAnswers.push(givenAnswerObj);
    } else if (selected && givenAnswersIndex >= 0) {
      answerObj[index].givenAnswers[givenAnswersIndex] = givenAnswerObj;
    } else if (!selected) {
      answerObj[index].givenAnswers.splice(givenAnswersIndex, 1);
    }

    // Get all answers that contain CONSULTATION
    const rawObj = answerObj.filter(x => x.givenAnswers.flatMap(y => y.answeredBy).includes(this.answerType));
    // Create Object to send to the Backend, only with CONSULTATION answers
    sendObj = this.createAnswerSendObject(rawObj, this.answerType);

    // make sure multi answers have no value sent to the backend
    sendObj.forEach(x => (x.value = null));

    this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
  }

  public answerSelectSingle(
    answerObj: AnswerOptionItem[],
    questionId: string,
    index: number,
    value: boolean | any,
    skipMapping: boolean = false
  ) {
    const notice = typeof value === 'boolean' ? null : value.note;

    let sendObj = [] as UpdateAnswerItem[];

    for (const answer of answerObj) {
      answer.givenAnswers = answer.givenAnswers.filter(x => x.answeredBy !== this.answerType);
    }
    if (value !== '') {
      answerObj[index].givenAnswers.push({ answeredBy: this.answerType, note: notice });
    } else {
      answerObj[index].givenAnswers.push({ answeredBy: GivenAnswerEnum.none, note: notice });
    }

    sendObj = this.createAnswerSendObject(answerObj, this.answerType);

    if (sendObj.length > 0) {
      !skipMapping && this.singleAnswerData.set(questionId, sendObj[0].id);
      this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
    }
  }

  public answerSelectSegment(answerObj: AnswerOptionItem[], questionId: string, value: string) {
    const index = answerObj.findIndex(x => String(x.id) === String(value));

    this.answerSelectSingle(answerObj, questionId, index, String(value));
  }

  public answerSelectImage(answerObj: AnswerOptionItem[], questionId, isMultiImage, value: any) {
    let sendObj = [] as UpdateAnswerItem[];
    const localAnswerObject = [] as AnswerOptionItem[];

    // eslint-disable-next-line guard-for-in
    for (const index in answerObj) {
      const y = value.findIndex(x => String(x) === String(answerObj[index].id)) !== -1;
      localAnswerObject[index] = { id: answerObj[index].id, givenAnswers: [] as GivenAnswerItem[] };
      if (y) {
        localAnswerObject[index].givenAnswers.push({ answeredBy: this.answerType });
      } else {
        localAnswerObject[index].givenAnswers.push({ answeredBy: GivenAnswerEnum.none });
      }
    }

    sendObj = this.createAnswerSendObject(localAnswerObject, this.answerType);

    // make sure multi answers have no value sent to the backend
    if (isMultiImage) {
      sendObj.forEach(x => (x.value = null));
    }

    this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
  }

  public answerSelectNumeric(answerObj: AnswerOptionItem[], questionId, value) {
    const sendObj = [] as UpdateAnswerItem[];

    for (const answer of answerObj) {
      if (answer.minValue <= value && answer.maxValue >= value) {
        let noteValue = '';
        if (this.prevAnswerObj && this.prevAnswerObj.length > 0) {
          if (this.prevAnswerObj[0].id === answer.id) {
            noteValue = this.prevAnswerObj[0].note;
          }
        }
        sendObj.push({
          id: answer.id,
          value: value,
          note: noteValue,
          answeredBy: GivenAnswerEnum.none,
        });
        break;
      }
    }

    sendObj[0].answeredBy = this.answerType;
    this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
  }

  public answerSelectSlider(question: QuestionItem, event) {
    const answerObj = question.answers;
    const questionId = question.id;

    const sendObj = [] as UpdateAnswerItem[];

    for (const answer of answerObj) {
      if (answer.minValue <= event.value && answer.maxValue >= event.value) {
        let noteValue = '';
        if (this.prevAnswerObj && this.prevAnswerObj.length > 0) {
          if (this.prevAnswerObj[0].id === answer.id) {
            noteValue = this.prevAnswerObj[0].note;
          }
        }
        sendObj.push({
          id: answer.id,
          value: event.value,
          note: event.noteInputValue || '',
          answeredBy: GivenAnswerEnum.none,
        });
        break;
      }
    }

    const resetNote = this.updateSliderValues(question, event);

    if (resetNote) {
      sendObj[0].note = '';
    }

    sendObj[0].answeredBy = this.answerType;
    this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
  }

  public answerSelectText(answerObj: AnswerOptionItem[], questionId, value) {
    const sendObj = [] as UpdateAnswerItem[];
    for (const answer of answerObj) {
      let noteValue = '';
      if (this.prevAnswerObj && this.prevAnswerObj.length > 0) {
        if (this.prevAnswerObj[0].id === answer.id) {
          noteValue = this.prevAnswerObj[0].note;
        }
      }
      sendObj.push({
        id: answer.id,
        value: value,
        note: noteValue,
        answeredBy: GivenAnswerEnum.none,
      });
      break;
    }

    sendObj[0].answeredBy = this.answerType;
    this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
  }

  ////// ----------------------------------------- //////
  ////// ----------------------------------------- //////
  ////// ------ TEXT FIELD HELPER FUNCTIONS ----- //////
  ////// ----------------------------------------- //////
  ////// ----------------------------------------- //////

  public showSliderTextFieldData(question: QuestionItem) {
    if (!this.showTextMap.has(question.id)) {
      return -1;
    } else if (
      this.showTextMap.has(question.id) &&
      this.showTextMap.get(question.id).find(x => x.givenAnswers.length > 0)
    ) {
      return this.showTextMap.get(question.id).find(x => x.givenAnswers.length > 0).id;
    }
  }

  public getTextFieldData(question: QuestionItem, answerId: string = this.UUIDNull, property: string = 'id') {
    if (!this.showTextMap.has(question.id)) {
      return '';
    }
    let returnData = {} as AnswerOptionItem;

    if (answerId === this.UUIDNull) {
      returnData = this.showTextMap.get(question.id)[0];
    } else {
      const index = this.showTextMap.get(question.id).findIndex(x => x.id === answerId);
      if (index === -1) {
        return '';
      }
      returnData = this.showTextMap.get(question.id)[index];
    }

    if (property !== 'givenAnswers') {
      return returnData[property] || '';
    } else {
      return returnData.givenAnswers?.find(givenAnswer => givenAnswer.answeredBy === this.answerType)?.note || '';
    }
  }

  public handleTextFieldInput(value: string, questionId: string, answerId: string = this.UUIDNull) {
    if (answerId == this.UUIDNull) {
      this.prevAnswerObj[0].note = value;
      this.sendAnswer(this.clientService.consultationId, questionId, this.prevAnswerObj);
    }
  }
  public showTextFieldData(question: QuestionItem, answerId: string = this.UUIDNull) {
    if (answerId == this.UUIDNull) {
      return this.showTextMap.has(question.id);
    } else {
      if (this.showTextMap.has(question.id)) {
        return this.showTextMap.get(question.id).findIndex(x => x.id === answerId) >= 0;
      }
      return false;
    }
  }

  ////// ------------------------------------- //////
  ////// ------------------------------------- //////
  ////// ----- PRIVATE HELPER FUNCTIONS ------ //////
  ////// ------------------------------------- //////
  ////// ------------------------------------- //////

  private updateSliderValues(question: QuestionItem, event): boolean {
    let resetNoteInput = this.shouldReset(question, event);

    this.numericalAnswerData.set(question.id, {
      defaultSliderValue: false,
      value: event.value,
      note: resetNoteInput ? '' : event.noteInputValue,
      answerId: this.getCurrentNumericalAnswerId(question, event.value),
    });

    return resetNoteInput;
  }

  private shouldReset(question, event): boolean {
    const prevAnswer = this.numericalAnswerData.get(question.id);

    if (!prevAnswer.answerId) {
      return false;
    }

    return prevAnswer.answerId !== this.getCurrentNumericalAnswerId(question, event.value);
  }

  private getCurrentNumericalAnswerId(question: QuestionItem, value): string {
    return question.answers.filter(x => value <= x.maxValue && value >= x.minValue)[0].id;
  }

  private getCurrentNumericalAnswerOption(question: QuestionItem): AnswerOptionItem {
    const value = this.numericalAnswerData.get(question.id).value;
    return question.answers.filter(x => value <= x.maxValue && value >= x.minValue)[0];
  }

  ////// ------------------------------------- //////
  ////// ------------------------------------- //////
  ////// --------- PUBLIC FUNCTIONS ---------- //////
  ////// ------------------------------------- //////
  ////// ------------------------------------- //////

  public showPreselectedState(givenAnswersObj: GivenAnswerItem[]) {
    return givenAnswersObj.findIndex(x => x.answeredBy === GivenAnswerEnum.customer) >= 0;
  }

  public isQuestionShown(question: QuestionItem) {
    if (question.showQuestion !== undefined && !question.showQuestion) {
      return false;
    }
    return true;
  }

  public isQuestionPaired(question: QuestionItem): boolean {
    return question.syncedAnswers?.findIndex(x => x.answeredBy === GivenAnswerEnum.consultant) >= 0;
  }

  public getPairedQuestionAnswer(question: QuestionItem): string[] {
    let returnValue: SyncedAnswers;
    returnValue = question.syncedAnswers.find(x => x.answeredBy === this.answerType);
    return this.syncedAnswerTextFactory(returnValue);
  }

  public getStepValue(question: QuestionItem): number {
    if (question.type !== this.questionEnum.expSlider && question.stepValue === 0) {
      return 1;
    } else {
      return question.stepValue;
    }
  }

  public getSliderQuestionLabel(questionName: string): string {
    const questionString = questionName.split('#/#');
    if (questionString.length > 1) {
      return `${questionString[questionString.length - 2]}#/#${questionString[questionString.length - 1]}`;
    } else {
      return questionName;
    }
  }

  public getSliderQuestionHeader(questionName: string): string {
    const questionString = questionName.split('#/#');
    if (questionString.length > 1) {
      return questionString[0];
    } else {
      return questionName;
    }
  }

  public getTextValue(question: QuestionItem) {
    const currentAnswer = question.answers[0]?.givenAnswers?.find(x => x.answeredBy === this.answerType);
    return currentAnswer ? currentAnswer.value : '';
  }

  public isDebugInQuestionHeadingShown(question: QuestionItem) {
    return question.type === QuestionEnum.variantCalculator && this.configService.isDebugMode;
  }

  public jumpToQuestionGroup(qgId) {
    this.currentIndex = this.availableCollections.indexOf(qgId[1].id);
    this.doAction('prelim-main', 'jump', { questionGroupId: qgId[1].id });
  }

  public questionHasTextFields(question: QuestionItem): boolean {
    return question.answers.filter(answer => answer.hasTextField).length > 0;
  }

  public changeSyncQuestionState(questionId: string): void {
    !this.showSyncQuestionSet.has(questionId)
      ? this.showSyncQuestionSet.add(questionId)
      : this.showSyncQuestionSet.delete(questionId);
  }

  public showSyncedQuestions(questionId: string): boolean {
    return this.showSyncQuestionSet.has(questionId);
  }

  public getNotesLabel(question: QuestionItem): string {
    return this.getCurrentNumericalAnswerOption(question)?.label;
  }

  public getNotesInput(question: QuestionItem): string {
    return this.numericalAnswerData.get(question.id).note;
  }

  public showsNotesInput(question: QuestionItem): boolean {
    return this.getCurrentNumericalAnswerOption(question)?.hasTextField;
  }

  get answerType(): GivenAnswerEnum {
    if (environment.consultationType === 'prelim') {
      return GivenAnswerEnum.customer;
    } else {
      this.contextService.currentMode === 'main' ? GivenAnswerEnum.consultation : GivenAnswerEnum.consultant;
    }
    return GivenAnswerEnum.customer;
  }

  public tooManyMarks(question: QuestionItem): boolean {
    const values = this.numericalAnswerMinMax.get(question.id);
    if (!values || question.type !== QuestionEnum.slider) {
      return false;
    }
    return (values?.max - values?.min) / question?.stepValue > 10;
  }

  public setTempTextAnswer(
    questionId: string,
    value: boolean | any,
    type: 'text' | 'text-input' | 'multi' | 'single',
    answerObj?: AnswerOptionItem[],
    index: number = -1
  ): void {
    this.tempTextAnswer = { answerObj, questionId, index, value, type };
  }

  public sendTempTextRequest(): void {
    if (!this.tempTextAnswer) {
      return;
    }

    const tempAnswer = { ...this.tempTextAnswer };
    this.tempTextAnswer = null;

    if (tempAnswer.type === 'text') {
      this.answerSelectText(tempAnswer.answerObj, tempAnswer.questionId, tempAnswer.value);
    } else if (tempAnswer.type === 'multi') {
      this.answerSelectMulti(tempAnswer.answerObj, tempAnswer.questionId, tempAnswer.index, tempAnswer.value);
    } else if (tempAnswer.type === 'single') {
      this.answerSelectSingle(
        tempAnswer.answerObj,
        tempAnswer.questionId,
        tempAnswer.index,
        tempAnswer.value,
        this.previousAnswerRequest?.questionId === tempAnswer.questionId
      );
    } else if (tempAnswer.type === 'text-input') {
      this.handleTextFieldInput(tempAnswer.value, tempAnswer.questionId);
    }
  }

  public getQuestionImage(question: QuestionItem): VrImageData {
    return { url: this.mediaMap.get(question.id) || '', altText: '' };
  }
}
